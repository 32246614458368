import React from "react"
import coverImage from "../../images/about/our-mission/our-mission-cover.jpg"
import StandardLayout from "../../layouts/standard"

const data = [
  {
    name: "Inside Trading Code",
    link:
      "https://sty-web.s3.amazonaws.com/investors/Policy/Inside+Trading+Code.pdf",
  },
  {
    name: "Policy on Preservation of Documents",
    link:
      "https://sty-web.s3.amazonaws.com/investors/Policy/POLICY+ON+PRESERVATION+OF+DOCUMENTS.pdf",
  },
  {
    name: "Risk Management Policy",
    link:
      "https://sty-web.s3.amazonaws.com/investors/Policy/RISK+MANAGEMENT+POLICY.pdf",
  },
  {
    name: "CSR Policy",
    link: "https://sty-web.s3.amazonaws.com/investors/Policy/CSR+Policy.pdf",
  },
  {
    name: "Dividend Distribution Policy",
    link:
      "https://sty-web.s3.amazonaws.com/investors/Policy/DIVIDEND+DISTRIBUTION+POLICY.pdf",
  },
  {
    name: "Nomination and Remuneration Policy",
    link:
      "https://sty-web.s3.amazonaws.com/investors/Policy/NOMINATION+AND+REMUNERATION+POLICY.pdf",
  },
  {
    name: "Policy on Determination of Materiality of Events",
    link:
      "https://sty-web.s3.amazonaws.com/investors/Policy/POLICY+ON+DETERMINATION+OF+MATERIALITY+OF+EVENTS.pdf",
  },
  {
    name: "Policy on  Material Subsidiaries",
    link:
      "https://sty-web.s3.amazonaws.com/investors/Policy/POLICY+ON+MATERIAL+SUBSIDIARIES.pdf",
  },
  {
    name: "Prevention of Sexual Harassment Policy",
    link:
      "https://sty-web.s3.amazonaws.com/investors/Policy/PREVENTION+OF+SEXUAL+HARASSMENT+POLICY.pdf",
  },
  {
    name: "Related Party Transactions",
    link:
      "https://sty-web.s3.amazonaws.com/investors/Policy/RELATED+PARTY+TRANSACTIONS.pdf",
  },
]

export default function CompanyPolicyPage(props) {
  return (
    <StandardLayout title="Catalogues">
      <div className="cover-cont">
        <img src={coverImage} alt="" />
        <div>Company Policy</div>
      </div>

      <div className="catalog-head">Company Policy</div>

      <div className="brochures-cont">
        {data.map(d => (
          <div>
            <div className="brochure-title">{d.name}</div>
            <a target="_blank" href={d.link} className="brochure-link">
              View
            </a>
          </div>
        ))}
      </div>
    </StandardLayout>
  )
}
